import { combineReducers } from 'redux-merge-immutable-reducers';
import customer from './customer/customer';
import availCache from '@synergycms_core/store/avail/availCache';
import app from '@synergycms_core/store/app/app';
import hotels from '@synergycms_core/store/hotels/hotels';
import system from '@synergycms_core/store/customer/system';
import actions from './actions';
import {  fromJS } from 'immutable';
import { handleActions } from 'redux-actions';


const group = handleActions(
  {
    [actions.group.get.toString()]: (store, action) => {
      return fromJS(action.payload);
    }
  },
  null
);

//REDUCERS
export default combineReducers({ customer, availCache, group, hotels, app, system });
