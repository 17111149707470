import React from 'react';
import Cookies from '@synergycms_core/utils/cookies';
import Engine from '@synergycms_core/components/functional/Engine';
import ParamsParser from '@synergycms_core/utils/paramsParser';
import { compose, pipe, filter, pluck, is, length, merge, has } from "ramda";
import { get } from '@synergycms_core/utils'
import { toIsoLangCode } from '@synergycms_core/utils/api';
import { connect } from 'react-redux';
import actions from './store/actions';
import { fromJS, Map } from 'immutable';
import moment from 'moment';
import PropertyManager from '@synergycms_core/components/HoC/propertyManager';
import { enableCachePrices, contextualizeAppConfig } from "@synergycms_core/store/app/utils";

import validateAsyncComponent from '@synergycms_core/components/HoC/validateAsyncComponent';
import Button from '@synergycms_core/components/generic/Button';
import { withThemeJSS } from '@synergycms_core/components/providers/themeProvider';
import { withContent } from '@synergycms_core/components/providers/translateProvider';
import ReactDOM from 'react-dom';
import styleFile from './app.js';
import {  langCodeCorrector } from '@synergycms_core/utils/api';
import { defaults } from "@synergycms_core/utils";

import { omit } from 'ramda';

const AvailButton = (props) => {
  return <Button
      onClick={props.click}
      richText={{ text: '_%motor%_', translate: true, ucFirst: true }}
    />
};

const app = class App extends React.Component {
  static displayName = 'App';

  constructor(props) {
    super(props);
    this.state = {
      dropDown: true
    };
    this.cx = props.theme.bind();
  }

  dispatchEvent = queryParams => {
    if (typeof window.CustomEvent === 'function') {

      const additionalQueryParams = {
        href: window.location.href
      };

      if (has('hotelCodes', queryParams)) {
        additionalQueryParams.hotels = queryParams.hotelCodes;
      } else if (has('hotelCode', queryParams)) {
        additionalQueryParams.id_hotel = queryParams.hotelCode;
      }

      const event = new CustomEvent('engine_search', {
        detail: {
          engine: merge(additionalQueryParams, queryParams)
        }
      });
      document.dispatchEvent(event);
    }
  };

  getOtherParams = () => {
    const queryParams = {};

    // JSON SID
    if (window.json_sid) {
      queryParams.sid = window.json_sid;
    }

    // Get URL Params
    const urlParams = [
      'avail_code',
      'company_id',
      'referer_code',
      'gift_voucher',
      'id_referer',
      'promotion_code',
      'td',
      'msclkid',
      'trv_reference',
      'clickID',
      'refid',
      'gclid',
      'yclid',
      'start',
      'end',
      '_askSI'
    ];


    urlParams.forEach(urlParam => {

      // consult urlParam in cookies and get this value
      const cookieValue = Cookies.get(urlParam);
      if (cookieValue) {
        queryParams[urlParam] = cookieValue;
      }
      const value = new RegExp('[?&]' + urlParam + '=([^&#]*)').exec(window.location.href);
      if (length(value)) {
        queryParams[urlParam] = get(1, value);
      }

    });

    return queryParams;
  };

  isMobile = () => {
    return this.props.theme.deviceType !== 'DESK';
  };

  executeSearch = (objectParams) => {
    const { data } = this.props.config;
    let hotels = null;
    let hotelCode = null;
    const selected = objectParams.selectedSearch;
    if (data.web_type === "PORTAL" || (data.web_type === "WI" && data.group === "eurostarshotels")) {
      const country_id = !selected.country_id && !selected.code ? selected.id : null;
      const city_id = selected.country_id ? selected.id : null;

      hotelCode = selected.id && selected.code ? selected.code : null;
      if (city_id) {
        hotels = pipe(
            filter(hotel => hotel.city_id === city_id),
            pluck('code')
        )(data.hotels_info.hotels);
      } else if (country_id) {
        hotels = pipe(
            filter(hotel => hotel.country_id === country_id),
            pluck('code')
        )(data.hotels_info.hotels);
      }
    } else {
      hotelCode = data.hotel_code;
    }

    if (hotels && hotels.length === 1) {
      hotelCode = hotels[0];
      hotels = null;
    }

    const queryParams = merge({
      hotelCodes: hotels,
      hotelCode: hotelCode,
      city_id: (selected.country_id && !selected.code ) ? selected.id : (selected.city_id) ? selected.city_id : null,
      country_id: !selected.country_id && !selected.code ? selected.id : null,
      start: objectParams.start,
      end: objectParams.end,
      occups: objectParams.occups,
      promotion_code: objectParams.promoCode,
    }, this.getOtherParams());

    this.dispatchEvent(queryParams);

    const urlParms = ParamsParser.convertParamsToStringQuery(queryParams);

    let uri = "";
    if (is(String, data.url)) {
      uri = data.url;
    } else {
      uri = data.url[toIsoLangCode(data.lang)];
      uri = uri ? uri : uri["en"];
    }

    //cuando hay solo un hotel, ir a su mono si enable_wi_url existe
    if(hotelCode && !!data.enable_wi_url){
      const hotelInfo = data.hotels_info?.hotels?.find(function (hotel) {
        return hotelCode === hotel.code;
      });
      if(hotelInfo){
        uri = hotelInfo.avail;
      }
    }

    window.location.href = uri + '?' + urlParms;
  };

  clickButtonShowEngine = e => {
    e.preventDefault();
    this.setState((state) => { return { dropDown: !this.state.dropDown}})
  }

  render() {
    const urlParams = this.getOtherParams();
    const { config, theme } = this.props;
    const { data, engineActive, pricesActive, engineType, id_button_engine, positions } = config;

    const { classes } = theme.styles

    // Si engineActive es false, no renderiza el motor.
    if (!engineActive) {
      return null;
    }

    const engine = <Engine
      executeSearch={this.executeSearch}
      selectSearch={config.selectSearch}
      onChangeCalendar={pricesActive && config.onChangeCalendar}
      params={{ ...data, hotelCode: data.hotel_code, promotion_code: urlParams["promotion_code"],start: urlParams["start"], end: urlParams["end"] }}
      group={data.group}
      corporateLogin={data.corporate_login}
      inAvail={false}
      hotelsInfo={data.hotels_info}
      match={data.match}
      outerWeb={data.outer_web}
      limits={data.limits}
      childsInfo={data.occups_free}
      engineVisible={data.engine_visible}
      dropDownListType={data.drop_down_list_type}
      showModalView={data.show_modal_view}
      focusOnModalView={data.focus_on_modal_view}
      webType={data.web_type}
      lang={data.lang}
      engineSygyData={data.engine_sygy_data}
      links={data.links}
      extraInfo={
        {
          ...(data.hotels_catalog && {hotels_catalog: data.hotels_catalog}),
          ...(data.enable_promotion_code && {enable_promotion_code: data.enable_promotion_code}),
        }
      }
    />

    return (<>
      {!id_button_engine &&
        <>
          <div className={[theme.computedInnerStyle, this.cx({
            customPosition:positions,
            hideEngine: engineType === 'button' && this.state.dropDown })].join(' ')}>
              {engine}
          </div>

          {engineType === 'button' &&
            <div className={[classes.engineBtn].join(' ')}>
              <AvailButton click={this.clickButtonShowEngine} />
            </div>
          }
        </>
      }

      {engineType === 'button' && id_button_engine && ReactDOM.createPortal(<>
          <AvailButton click={this.clickButtonShowEngine}/>
          {!this.state.dropDown && <div className={[theme.computedInnerStyle, this.cx(
        { customPosition:positions })].join(' ')} >
              {engine}
          </div>
          }</>, document.getElementById(id_button_engine))
      }
    </>)

  };

  componentDidMount = () => {
    const { config } = this.props;

    if (config.outerWeb) {
      this.paramsToCookies();
    } else {
      this.props.config.selectSearch();
    }
  }

  //Guardar en cookies
  paramsToCookies() {
    const queryParams = this.getOtherParams();
    var cookieParams = ["referer_code"];

    cookieParams.forEach(function (code) {
      var value = queryParams[code];
      if(value){
        Cookies.set(code, value, 30);
      }
    })

  }
}

const mapDispatchToProps = dispatch => {
  return {
    onChangeCalendar: ({ currentFirstMonth, months }) => {
      if (currentFirstMonth >= moment().startOf("day")) {
        const current = moment();
        const start = current > currentFirstMonth ? current : moment(currentFirstMonth).startOf("month");
        const end = moment(start).add(months - 1, "month").endOf("month");
        dispatch(actions.availCache.getAsync(start, end));
      }
    },
    selectSearch: (selected, range = 3) => {
      if (selected) {
        const hotelId = selected.id && selected.code ? selected.id : null;
        const selection = fromJS({
          date: selected.start,
          city_id: !hotelId && selected.country_id ? selected.id : null,
          country_id: !selected.country_id && !selected.code ? selected.id : null,
          hotelId: hotelId
        });
        dispatch(actions.customer.params.availCacheParams(selection));
        dispatch(actions.customer.currentCurrency.set());
      }

      dispatch(actions.availCache.getAsync(moment(), moment().add(range - 1, "month").endOf("month")));
    }
  };
};

const mapStateToProps = (store, ownProps) => {
  const hotelId = store.getIn(['customer', 'current_hotel']);
  const engineConfigs = contextualizeAppConfig(store, hotelId, "engine_config");


  const maxRooms = engineConfigs.get('max_rooms') || engineConfigs.getIn(['limits', 'rooms', 'max'], 10)
  const maxAdults = engineConfigs.get('max_adults') !== undefined ? engineConfigs.get('max_adults') : engineConfigs.getIn(['limits', 'adults', 'max'], 8)
  const defaultAdults = engineConfigs.get('default_adults') !== undefined ? engineConfigs.get('default_adults') : engineConfigs.getIn(['limits', 'adults', 'default'], 2)
  const maxChilds = engineConfigs.get('max_childs') !== undefined ? engineConfigs.get( 'max_childs') : engineConfigs.getIn(['limits', 'childs', 'max'], 0)
  const defaultChilds = engineConfigs.get('default_childs') !== undefined ? engineConfigs.get('default_childs') : engineConfigs.getIn(['limits', 'childs', 'default'], 0)

  const childsInfo = engineConfigs.get('occups_free', null);
  const enableAge = (!!childsInfo && Number.isFinite(childsInfo?.baby?.start) && Number.isFinite(childsInfo?.child?.end));
  const defaultAge = engineConfigs.get('default_babies_age', null);
  let defaultChildAge = null;
  if(enableAge && Number.isFinite(defaultAge)){
    defaultChildAge = defaultAge;
  } else if(enableAge) {
    defaultChildAge = childsInfo.child.end || 11;
  }

  const lang = store.getIn(['customer', 'lang_info', 'code']) || engineConfigs.get('lang');
  const webType = store.getIn(['customer', 'web_type'])

  let hotels_info = undefined
  if(webType === "PORTAL"){
    hotels_info= store.getIn(['hotels', 'hotels_info'])
    hotels_info = hotels_info && Map.isMap(hotels_info) ? hotels_info.toJS() : hotels_info;
  }else if(webType === "WI"){
    hotels_info = null
  }

  let theme_values = engineConfigs.get('theme_values')
  theme_values = theme_values && Map.isMap(theme_values) ? theme_values.toJS() : theme_values;

  const ownPropsData = {...omit(['config'], ownProps.data), ...ownProps.data.config}
  let data = {...ownPropsData,
    //hotel_id: engineConfigs.get('hotel_id'),
    engine_active: engineConfigs.get('engine_active', true),
    price_active: enableCachePrices(store),
    limits : {
      'rooms':{
        'max': maxRooms,
        'min': 1
      },
      'adults':{
        'max': maxAdults,
        'min': 1,
        'default': defaultAdults,
      },
      'childs':{
        'max': maxChilds,
        'min': 0,
        'default': defaultChilds,
        'defaultAge': defaultChildAge
      }
    },
    occups: {
      "0": {
        "adults": defaultAdults,
        "childs": defaultChilds
      }
    },
    outer_web : engineConfigs.get('outer_web', true),
    enable_wi_url : engineConfigs.get('enable_wi_url'),
    show_modal_view : engineConfigs.get('show_modal_view', false),
    focus_on_modal_view : engineConfigs.get('focus_on_modal_view', true),
    engine_visible : engineConfigs.get('engine_visible', true),
    drop_down_list_type : engineConfigs.get('drop_down_list_type') ? 'nested_destinations' : null,
    theme_values : theme_values,
    theme : engineConfigs.get('theme'),
    occups_free: enableAge && engineConfigs.get('occups_free', true),
    ignore_synergy_config: engineConfigs.get('ignore_synergy_config', true),
    enable_promotion_code: engineConfigs.get('enable_promotion_code', false),
    hotels_catalog: webType === "PORTAL" ? engineConfigs.getIn(['hotels_catalog', lang]) : null,
    links: engineConfigs.get('links', null),
    url: engineConfigs.get('avail_url') || engineConfigs.getIn(['url', lang]) || engineConfigs.get('url'),
    lang,
    web_type : webType,
    group: store.get('group'),
    i18n: engineConfigs.get('i18n'),
    hotels_info:hotels_info
  }


  if(ownProps.data.hotel_id){
    data.hotel_id = ownProps.data.hotel_id;
  }else if(window.frontLoader?.hotel_id){
    data.hotel_id = window.frontLoader.hotel_id;
  }
  if(ownProps.data.hotel_code){
    data.hotel_code = ownProps.data.hotel_code;
  }else if(window.frontLoader?.hotel_code){
    data.hotel_code = window.frontLoader.hotel_code;
  }

  if(ownProps.data.country_id){
    data.country_id = ownProps.data.country_id;
  }else if(window.frontLoader?.country_id){
    data.country_id = window.frontLoader.country_id;
  }
  if(ownProps.data.city_id){
    data.city_id = ownProps.data.city_id;
  }else if(window.frontLoader?.city_id){
    data.city_id = window.frontLoader.city_id;
  }


  const positions = engineConfigs.get('positions', null);
  const engineType = engineConfigs.get('engine_type', 'standard');
  const id_button_engine = (engineType !== 'button') ? null : engineConfigs.get('id_button_engine', null);


  if (data) {
    if (is(Array, data)) {
      data = data.map((config) => {
        return setDefaults(config);
      });
    } else {
      data = setDefaults(data);
    }
  }

  return {
    engineActive:data.engine_active,
    pricesActive:data.price_active,
    hotels_info: data.hotels_info,

    engineType,
    id_button_engine,
    positions,
    data
  };
};


export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    validateAsyncComponent(mapStateToProps),
    PropertyManager([
      'pricesActive',
      'data',
      'engineActive',
      'selectSearch',
      'onChangeCalendar',
      'engineType',
      'id_button_engine',
      'positions'
    ]),
    withThemeJSS({ styleFile, link: true }),
    withContent('i18n')
)(app);



const setDefaults = (engineSygyData) => {

  let result = null;

  if (engineSygyData && (engineSygyData.outer_web === false)) {
    const hotelCode = (engineSygyData && engineSygyData.hotel_code) || window.hotel_code || window.id_hotel;
    const hotelId = (engineSygyData && engineSygyData.hotel_id)

    const hotelsInfo = (engineSygyData && engineSygyData.hotels_info) || window.hotels_info;
    let webType = null;

    if (hotelCode || hotelId) {

      webType = "WI";
    } else if (hotelsInfo) {
      webType = "PORTAL"
    }
    if (engineSygyData.lang) {
      engineSygyData.lang = langCodeCorrector(engineSygyData.lang);
    }

    result = defaults(engineSygyData || {}, {
      outer_web: false,
      element_id: "default_sygy_element_engine",
      url: window.avail_url,
      lang: langCodeCorrector(window.lang_info ? window.lang_info.code : "es"),
      occups: { 0: { adults: 2, childs: 0 } },
      limits: window.occup_limits,
      hotel_code: hotelCode,
      hotels_info: hotelsInfo,
      web_type: webType,
      show_modal_view: window.show_modal_view || false,
      drop_down_list_type: null,
      focus_on_modal_view: true,
      country_id: window.country_id || null,
      city_id: window.city_id || null,
      start: null,
      end: null,
      theme_values: null,
      engine_visible: true,
      theme: window.synergyCmsAvailTheme || null,
      links: {},
      i18n: window.i18n,
      engine_sygy_data: engineSygyData,
      corporate_login: window.LoginUser && +window.LoginUser.tipo_perfil === 1 ? window.LoginUser : null,
      star_traveler_login: window.LoginUser && +window.LoginUser.tipo_perfil === 2 ? window.LoginUser : null,
    });
  } else {

    const hotelCode = engineSygyData && engineSygyData.hotel_code;
    const hotelsInfo = engineSygyData && engineSygyData.hotels_info;
    let webType = null;
    if (hotelCode) {
      webType = "WI";
    } else if (hotelsInfo) {
      webType = "PORTAL"
    }

    result = defaults(engineSygyData || {}, {
      outer_web: true,  //si es web externa, per defecte es configura com a motor extern
      element_id: "default_sygy_element_engine", //element html on es carregara el motor
      url: null, // url per llançar les peticions de disponiblitat es pot estructurar per idioma {es:url,en:url} o passar string si es unica
      match: null, // config preseleccionat per defecte només portal
      lang: "es", //idioma actual en q es mostra el motor
      occups: { 0: { adults: 2, childs: 0 } }, // configuració preseleccionada default d'ocupaccions al carregar el motor
      limits: null, //limits de configuracio per les ocupaccions y al agregar nova habitació : { rooms = { max: 10, min:1 }, adults = { max:8, min:1, default: 2 }, childs = { igual q adult } }
      hotel_code: hotelCode, // motor mono hotel WI, no mostra el buscador
      hotels_info: hotelsInfo, // motor multi hotel PORTAL, mostra el buscador amb les opcions configurades y el mateix format q hotels_info
      web_type: webType, // parametre preconfigurat per indicar si es portal o WI
      show_modal_view: false, // en versio mobil desplega automaticament el modal del MOTOR al carregar
      drop_down_list_type: null, //
      focus_on_modal_view: true, // establecer el foco en el input de busqueda al desplegar el motor
      start: null, //data inici per defecte sino agafa dia actual
      end: null, //data fi per defecte sino agafa 1 dia més q start
      theme_values: {}, // joc de colors utilitzats per pintar avail
      theme: null, // utilitzat per mostrar agrupacions de informacio o imatges estaticas s'utilitza amb withContent
      links: {},//
      engine_visible: true,//esconde o muestra el motor en vista mobil
      engine_sygy_data: engineSygyData // el mismo objecto q recibe el motor, sin perder el puntero en memoria, proporciana funciones al exterior para controlar el motor
    });
  }

  return result;
}