import { createActions } from 'redux-actions';

import availCacheActions from '@synergycms_core/store/avail/availCacheActions';
import customerActions from './customer/customerActions';
import appActions from '@synergycms_core/store/app/appActions';
import hotelsActions from '@synergycms_core/store/hotels/hotelsActions';
import systemActions from '@synergycms_core/store/customer/systemActions';

//ACTIONS
export default createActions({
  APP: appActions,
  HOTELS: hotelsActions,
  AVAIL_CACHE: availCacheActions,
  CUSTOMER: customerActions,
  GROUP: {
    GET: group => {
      return group;
    }
  },
  SYSTEM : systemActions,
});
