import mixins from '@synergycms_core/less/mixins.js';

export default {
    DESK:{},
    portalDesign:{},
    customPosition:{},
    hideEngine:{},
    defaultStyle:{
        '&$customPosition':{
            position: data => data.engine_position,
            top: (data) => {if (data.vertical_position === "top") return data.vertical_margin; return null},
            bottom: (data) => {if (data.vertical_position === "bottom") return data.vertical_margin; return null},
            right: (data) => {if (data.horizontal_position === "right") return data.horizontal_margin; return null},
            left: (data) => {if (data.horizontal_position === "left") return data.horizontal_margin; return null},
        },
        '&$hideEngine':{
            display: 'none'
        }
    },
    engineBtn:{
        position: data => data.btn_engine_position,
        top: (data) => {if (data.btn_vertical_position === "top") return data.btn_vertical_margin; return null},
        bottom: (data) => {if (data.btn_vertical_position === "bottom") return data.btn_vertical_margin; return null},
        right: (data) => {if (data.btn_horizontal_position === "right") return data.btn_horizontal_margin; return null},
        left: (data) => {if (data.btn_horizontal_position === "left") return data.btn_horizontal_margin; return null},
        
    }
};